import {
  defineStore,
  storeToRefs,
} from 'pinia';
import { API_PREFIX } from '~/constants/apiConfiguration';
import { LOCAL_STORAGE } from '~/constants/storedPropertiesNames';
import { useAuthStore } from '~/stores/auth';
import { useGlobalsStore } from '~/stores/globals';
import type {
  PaginationResponse,
  PaginationParams,
} from '~/types/general/globals';
import type {
  BonusCodeData,
  ActivateBonusRequest,
  FetchAvailableFreeSpinsRequest,
  SendConfirmationEmailRequest,
  PersonalDataParams,
  SavePlayerDataRequest,
  SendConfirmationSmsRequest,
  ValidateConfirmationRequest,
  SecurityData,
  MfaActivateRequest,
  MfaValidateRequest,
  MfaDeactivateRequest,
  PromotionItem,
  DocumentItem,
  GamesHistoryItem,
  GamesHistoryParams,
  RewardsHistoryItem,
  TransactionHistoryItem,
  TransactionHistoryParams,
  BonusesHistoryItem,
  BonusesHistoryParams,
  LastMonthPlayedGamesRequest,
} from '~/types/pages/cabinet';
import type { CabinetStateStore } from '~/types/stores/cabinet';

export const useCabinetStore = defineStore({
  id: 'cabinet',
  state: (): CabinetStateStore => ({
    promotionsData: [],
    promotionActivationStatus: '',
    documents: [],
    gamesHistoryItems: [],
    gamesHistoryPagination: {
      itemsOnPage: 0,
      page: 0,
      total: 0,
    },
    rewardsHistoryItems: [],
    rewardsHistoryPagination: {
      itemsOnPage: 0,
      page: 0,
      total: 0,
    },
    transactionHistoryItems: [],
    transactionHistoryPagination: {
      itemsOnPage: 0,
      page: 0,
      total: 0,
    },
    bonusesHistoryItems: [],
    bonusesHistoryPagination: {
      itemsOnPage: 0,
      page: 0,
      total: 0,
    },
    lastMonthPlayedGames: [],
  }),
  getters: {
    getPromotions: (state) => state.promotionsData,
    getDocuments: (state) => state.documents,
    getGamesHistoryItems: (state) => state.gamesHistoryItems,
    getGamesHistoryPagination: (state) => state.gamesHistoryPagination,
    getRewardsHistoryItems: (state) => state.rewardsHistoryItems,
    getRewardsHistoryPagination: (state) => state.rewardsHistoryPagination,
    getTransactionHistoryItems: (state) => state.transactionHistoryItems,
    getTransactionHistoryPagination: (state) => state.transactionHistoryPagination,
    getBonusesHistoryItems: (state) => state.bonusesHistoryItems,
    getBonusesHistoryPagination: (state) => state.bonusesHistoryPagination,
    getLastMonthPlayedGames: (state) => state.lastMonthPlayedGames,
  },
  actions: {
    async activateAccount(currency: string, type: string) {
      const authStore = useAuthStore();
      const { getUserData } = storeToRefs(authStore);

      try {
        await useMyFetch().post(`${API_PREFIX.account}/activateAccount`, {
          query: {
            currency,
            network: type,
          },
        });
        const currentBalance = getUserData.value.balances?.find((balance) => balance.currency === currency);

        if (currentBalance) {
          currentBalance.activated = true;
        }
      } catch(error) {
        throw error;
      }
    },
    async changeActiveCurrency(currency: string) {
      const { fetchUserBalances } = useAuthStore();

      try {
        await useMyFetch().post(`${API_PREFIX.account}/changeActiveCurrency`, { query: { currency } });
        await fetchUserBalances();
      } catch(error) {
        throw error;
      }
    },
    async activateBonus({ bonusCode }: BonusCodeData) {
      const { setNotification } = useGlobalsStore();

      try {
        const { data } = await useMyFetch().post(
          `${API_PREFIX.promotions}/bonuses/code/apply`,
          { query: { bonusCode } },
        ) as ActivateBonusRequest;

        setNotification({ dictionaryKeyText: 'cabinet_balance_promocode_bonus_will_be_soon' });

        return data;
      } catch(error) {
        throw error;
      }
    },
    async fetchPromotionsData(gamePlatform: string) {
      try {
        const { data } = await useMyFetch().get(`${API_PREFIX.promotions}/available`, {
          query: {
            forceNoCache: 'true',
            gamePlatform,
          },
        });

        this.promotionsData = data as PromotionItem[];
      } catch (error) {
        throw error;
      }
    },
    async participationAction(actionState: string, promotionId: number) {
      try {
        await useMyFetch().post(`${API_PREFIX.promotions}/participation`, {
          query: {
            action: actionState,
            promotionId,
          },
        });
      } catch (error) {
        throw error;
      }
    },
    async fetchFreeSpinsCount(platform: string) {
      const authStore = useAuthStore();
      const { getUserData } = storeToRefs(authStore);

      try {
        const { data } = await useMyFetch().get(`${API_PREFIX.promotions}/available-freespins-info`, {
          query: {
            lang: getUserData.value.locale,
            gamePlatform: platform,
          },
        }) as FetchAvailableFreeSpinsRequest;

        return data[0];
      } catch(error) {
        console.error(error);
      }
    },
    async sendConfirmationEmail() {
      const { setNotification } = useGlobalsStore();

      try {
        const { data } = await useMyFetch().get(
          `${API_PREFIX.player}/confirmation-email/send`) as SendConfirmationEmailRequest;

        if (data.success) {
          setNotification({
            dictionaryKeyText: 'cabinet_personal_data_send_confirmation_mail',
            isEmailConformation: true,
          });
        } else {
          setNotification({
            dictionaryKeyText: 'cabinet_personal_data_already_sended_confirmation_mail',
            isEmailConformation: true,
          });
        }

        return data.success;
      } catch (error) {
        throw(error);
      }
    },
    async validateConformationEmail(confirmationCode: string) {
      const { setNotification } = useGlobalsStore();
      const authStore = useAuthStore();

      try {
        const { data } = await useMyFetch().post(
          `${API_PREFIX.player}/confirmation-email/validate`,
          { query: { confirmationCode } },
        ) as ValidateConfirmationRequest;

        if (data.success) {
          authStore.userData.emailConfirmed = data.success;
          setNotification({
            dictionaryKeyTitle: 'notifications_email.title',
            dictionaryKeyText: 'notifications_email.text',
          });
        } else {
          return data.return.errorCode;
        }
      } catch (error) {
        throw(error);
      }
    },
    async savePlayerData(personalData: PersonalDataParams) {
      const { initializeUser } = useAuthStore();

      try {
        const { data } = await useMyFetch().post(
          `${API_PREFIX.playerProfile}/save-player-data`,
          { body: personalData },
        ) as SavePlayerDataRequest;

        if (data.success) {
          await initializeUser();
        }
      } catch (error) {
        throw error;
      }
    },
    async sendConfirmationSms() {
      try {
        const { data } =
          await useMyFetch().post(`${API_PREFIX.player}/confirmation-sms/send`) as SendConfirmationSmsRequest;

        if (data.success) {
          const { codeValidityInMinutes } = data.return;

          if (codeValidityInMinutes) {
            const currentDate = new Date();

            currentDate.setMinutes(currentDate.getMinutes() + codeValidityInMinutes);
            localStorage.setItem(LOCAL_STORAGE.codeValidityInMilliseconds, String(currentDate.getTime()));
          }
        } else {
          return data.return.errorCode;
        }
      } catch (error) {
        throw(error);
      }
    },
    async validateConfirmationSms(confirmationCode: string) {
      const authStore = useAuthStore();

      try {
        const { data } = await useMyFetch().post(
          `${API_PREFIX.player}/confirmation-sms/validate`,
          { query: { confirmationCode } },
        ) as ValidateConfirmationRequest;

        if (data.success) {
          authStore.userData.phoneConfirmed = data.success;
        } else {
          return data.return.errorCode;
        }
      } catch (error) {
        throw(error);
      }
    },
    async fetchDocuments() {
      const authStore = useAuthStore();
      const { getUserData } = storeToRefs(authStore);

      try {
        const { data } = await useMyFetch().get(`${API_PREFIX.playerVerification}/v2/documents`,  {
          query: {
            lang: getUserData.value.locale,
            playerId: getUserData.value.playerId,
          },
         });

         this.documents = data as DocumentItem[];
      } catch (error) {
        throw(error);
      }
    },
    async sendDocument(formData: FormData) {
      return useMyFetch().post(`${API_PREFIX.playerVerification}/v1/documents/upload`,  { body: formData });
    },
    setDocument(document: DocumentItem) {
      this.documents.push(document);
    },
    async fetchGamesHistory(params: GamesHistoryParams) {
      const authStore = useAuthStore();
      const { getUserData } = storeToRefs(authStore);

      try {
        const { data } = await useMyFetch().get(
          `${API_PREFIX.playerGamesHistory}/${getUserData.value.playerId}`, { query: { ...params } },
        );

        const {
          items,
          ...pagination
        } = data as {
          items: GamesHistoryItem[],
        } & PaginationResponse;

        this.gamesHistoryItems = items;
        this.gamesHistoryPagination = pagination;
      } catch (error) {
        throw(error);
      }
    },
    async fetchLastMonthPlayedGames() {
      const authStore = useAuthStore();
      const { getUserData } = storeToRefs(authStore);

      try {
        const { data } = await useMyFetch().get(
          `${API_PREFIX.playerGamesHistory}/games/${getUserData.value.playerId}`,
        ) as LastMonthPlayedGamesRequest;

        this.lastMonthPlayedGames = data.return;
      } catch (error) {
        throw(error);
      }
    },
    async fetchRewardsHistory(params: PaginationParams) {
      const authStore = useAuthStore();
      const { getUserData } = storeToRefs(authStore);

      try {
        const { data } = await useMyFetch().get(
          `${API_PREFIX.promotions}/rewards-history-paginated`,
          {
            query: {
              lang: getUserData.value.locale,
              ...params,
            },
          },
        );

        const {
          items,
          ...pagination
        } = data as {
          items: RewardsHistoryItem[],
        } & PaginationResponse;

        this.rewardsHistoryItems = items;
        this.rewardsHistoryPagination = pagination;
      } catch (error) {
        throw(error);
      }
    },
    async fetchTransactionHistory(params: TransactionHistoryParams) {
      const authStore = useAuthStore();
      const { getUserData } = storeToRefs(authStore);

      try {
        const { data } = await useMyFetch().get(
          `${API_PREFIX.transactionsHistory}/${getUserData.value.playerId}`,
          { query: params },
        );

        const {
          items,
          ...pagination
        } = data as {
          items: TransactionHistoryItem[],
        } & PaginationResponse;

        this.transactionHistoryItems = items;
        this.transactionHistoryPagination = pagination;
      } catch (error) {
        throw(error);
      }
    },
    async cancelTransaction(transactionId: string) {
      try {
        // TODO: Add additional env variable baseUrlPlain, rename staticBaseUrl
        // to remoteStorageUrl, do refactoring everywherywhere
        const staticBaseUrl = useBaseAppUrl().static;

        await useMyFetch().get(`${staticBaseUrl}/cashierproxy/api_sgs/operation/status`, {
          query: {
            status: 'failure',
            operation_id: transactionId,
          },
        });
      } catch (error) {
        throw error;
      }
    },
    async fetchBonusesHistory(params: BonusesHistoryParams) {
      try {
        const { data } = await useMyFetch().get(`${API_PREFIX.promotions}/bonuses/history-paginated`, { query: params });

        const {
          items,
          ...pagination
        } = data as {
          items: BonusesHistoryItem[],
        } & PaginationResponse;

        this.bonusesHistoryItems = items;
        this.bonusesHistoryPagination = pagination;
      } catch (error) {
        throw(error);
      }
    },
    async cancelBonus(bonusId: number) {
      try {
        await useMyFetch().post(`${API_PREFIX.promotions}/bonuses/cancel`, { query: { bonusId } });
      } catch (error) {
        throw error;
      }
    },
    async changePassword(securityData: SecurityData) {
      try {
        await useMyFetch().post(`${API_PREFIX.password}/change`, { body: { ...securityData } });
      } catch (error) {
        throw error;
      }
    },
    async mfaActivate() {
      try {
        const { data } = await useMyFetch().post(`${API_PREFIX.playerProfile}/mfa/activate`) as MfaActivateRequest;

        return data.return;
      } catch (error) {
        throw(error);
      }
    },
    async mfaValidate(code: string) {
      const authStore = useAuthStore();

      try {
        const { data } = await useMyFetch().post(
          `${API_PREFIX.playerProfile}/mfa/validate`,
          { query: { code } },
        ) as MfaValidateRequest;

        if (data.success) {
          authStore.userData.mfaEnabled = data.success;
        }
      } catch (error) {
        throw error;
      }
    },
    async mfaDeactivate(code: string) {
      const authStore = useAuthStore();

      try {
        const { data } = await useMyFetch().post(
          `${API_PREFIX.playerProfile}/mfa/deactivate`,
          { query: { code } },
        ) as MfaDeactivateRequest;

        if (data.success) {
          authStore.userData.mfaEnabled = !data.success;
        }
      } catch (error) {
        throw error;
      }
    },
  },
});
